import * as sdk from 'microsoft-cognitiveservices-speech-sdk'; 
// subscription key and region for speech services.
var subscriptionKey = 'f3e8298b12fc4d3ebdb061f61eb55a2b';
var serviceRegion = 'eastus';  
var synthesizer ;

async function StartSpeechRecord(lang) {

    // Simulate AI speaking with a progress bar

    var speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);

    speechConfig.speechRecognitionLanguage = lang;
    var audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    var recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);


    return new Promise((resolve, reject) => {
        recognizer.recognizeOnceAsync(
            (result) => {
                // Handle the successful result
                recognizer.close();
                recognizer = undefined;
                resolve(result);
            },
            (err) => {
                // Handle the error
                recognizer.close();
                recognizer = undefined;
                reject(err);
            }
        );
    }); 
}
async function StartTextToSpeech(inputText,lang) {
    // Simulate AI speaking with a progress bar 
    var speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);

    // speechConfig.autoDetectSourceLanguage = true;
    speechConfig.speechSynthesisLanguage = lang;
 
    speechConfig.speechSynthesisVoiceName = 
    // speechConfig.speechSynthesisVoiceName = lng;
    synthesizer = new sdk.SpeechSynthesizer(speechConfig);

    console.log("inputText,", inputText)
    synthesizer.speakTextAsync(inputText); 
}

function StopSpeechRecord(){ 
    console.log("start") 
    if (synthesizer) {
        synthesizer.close(); // Close the synthesizer
    }
    console.log("Stop") 
}


export {
    StartTextToSpeech,
    StartSpeechRecord ,
    StopSpeechRecord 
}
