import Bot from './components/bot.js';  
// import Chatbot from './components/Chatbot.js'  
import './App.css';
import mainLogo from './images/logo.png';  

function App() { 
  return ( 
  <div className="App"> 
  <div className='header'>
    <div className='headerlogo'>
    <a  href="https://urbansoft.co" target="_blank"><img src={mainLogo} height="80" alt="Urbansoft" loading="lazy" /></a> 
    </div> 
  </div> 
    {/* <ChatBot /> */}
   <Bot></Bot>   
    <div className="footer d-none">
      <div className='footer-content'>
        <a className="copyright-link" href="https://urbansoft.co" target="_blank">Urbansoft Technologies Middle East Co
            W.L.L.</a></div>
    </div>
  </div> 
  );
}

export default App;
