import './bot.css';
import React, { useState, useEffect ,useRef} from 'react';   
import 'reactjs-popup/dist/index.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane,faMicrophoneAltSlash,faMicrophone,faCirclePause } from '@fortawesome/free-solid-svg-icons'     
import { StartSpeechRecord,StartTextToSpeech,StopSpeechRecord } from './Speech';
import {sendChatRequest } from "./chat" 
import { botwelcomeimage, botimage2,roboticonsm,usericon} from '../images/index';  
 
export default function Bot() {   
const [conversationData, setconversationData] = useState([]); 
const UserInput = useRef(undefined);   
const [languagePrefer, setlanguagePrefer] = useState('en-US'); 
const [showcontentloading, setshowcontentloading] = useState(false);  
const [isRecording, setIsRecording] = useState(false);    
const [languagePreferAr, setlanguagePreferAr] = useState(true);      
const [alt, setalt] = useState(false);    
const handleClick = () => {
  if(languagePreferAr){
    setlanguagePrefer('ar-SA');
  }else{
    setlanguagePrefer('en-US'); 
  } 
  setlanguagePreferAr(!languagePreferAr);
  console.log(languagePrefer);
};

function ConversationWidget(props) {  
  
    var element = document.getElementById("chatboxcontainer");
    if(props.conversationType ==="outgoing"){
      if(props.contenttype ==="audio"){  
        // return (  
        //   // <li className="chat outgoing"> 
        //   //   <audio src={props.content} controls  />
        //   // </li> 
        //   );
      }else{ 
        return (  
          <li className="chat outgoing" key={element.scrollHeight}> 
          <p>{props.content}</p><span> <img src={usericon} className="botwelcomeimgEnsm" height="100" alt="Welcome" loading="lazy" /> </span>
          </li> 
          );
      } 
    }else if(props.conversationType ==="incoming"){ 
      if(props.contenttype ==="audio"){  
        // return (  
        //   // <li className="chat incoming"> 
        //   //   <audio src={props.content} controls  />
        //   // </li> 
        //   );
      }else{ 
        return (  
          <li className="chat incoming" key={element.scrollHeight}> 
               <span> <img src={roboticonsm} className="botwelcomeimgEnsm" height="100" alt="Welcome" loading="lazy" /> </span><p>{props.content}</p>  
          </li> 
          );
      }
    }  
}  
async function toggleRecording(event) {
 event.preventDefault(); 
 if (!isRecording) {
   setIsRecording(!isRecording); 
 }
 console.log(isRecording);
 if(!isRecording){

   try {
     // Step 1: Recording in progress... 
       const result_rec = await StartSpeechRecord(languagePrefer);
       //console.log(result_rec); 
       
       if (result_rec.privText.length > 0) {  
         // Step 2: Insert the user message to conversional list
         const userconversationitem  = {
             conversationType: "outgoing",  
             content: result_rec.privText,
             contenttype: "plaintext"
         }   
         setconversationData(current => [...current, userconversationitem]);   
         ScrollToEndOfList()
         setshowcontentloading(true); 
         ScrollToEndOfList();
         // Step 3: AI analysis in progress... 
         const result_chat = await sendChatRequest(result_rec.privText);
         //console.log(result_chat); 
         ScrollToEndOfList()
         if (result_chat.choices && result_chat.choices.length > 0) { 
        let responsechattext = result_chat.choices[0].message.content.replaceAll("[doc1]",'').replaceAll('[doc2]','').replaceAll('[doc3]','')
          .replaceAll('[doc4]','').replaceAll('[doc5]','').replaceAll('[doc6]','').replaceAll('[doc7]','').replaceAll('[doc8]','').replaceAll('[doc9]','').replaceAll('[doc10]','');
         // Step 4: Insert the user message to conversional list
         const AIconversationitem  = {
           conversationType: "incoming",  
           content: responsechattext,
           contenttype: "plaintext"
         }
         setconversationData(current => [...current, AIconversationitem]); 
         setshowcontentloading(false); 
         ScrollToEndOfList()
         // Step 5: Conversational AI speaking... 
         const result_chat_speak = await StartTextToSpeech(responsechattext,languagePrefer);
         
       }else{
         const AIconversationitem  = {
           conversationType: "incoming",  
           content: String("I'm unable to use a microphone. Please enable it for access my service."),
           contenttype: "plaintext"
         }
         setconversationData(current => [...current, AIconversationitem]);  
         setshowcontentloading(false); 
         ScrollToEndOfList();
         // Step 5: Conversational AI speaking... 
         const result_chat_speak = await StartTextToSpeech(String("I'm unable to use a microphone. Please enable it for access my service."),languagePrefer);
        } 
      } 
   }
   catch (error) {
       console.error(error);
   }
 } 
 setIsRecording(isRecording)  
}

function ScrollToEndOfList(){
  setTimeout(() => {  
    UserInput.current.value = ""; 
    var element = document.getElementById("chatboxcontainer");
    element.scrollTop = element.scrollHeight; 
  }, 500); 
} 

const generatetxtResponse = async (message) => {   
  UserInput.current.value = "";   
  const result_chat = await sendChatRequest(message);
    //console.log(result_chat);
  if (result_chat.choices && result_chat.choices.length > 0) {

    //console.log("result_chat.choices[0].message.content",result_chat.choices[0].message.content)
    let responsechattext = result_chat.choices[0].message.content.replaceAll("[doc1]",'').replaceAll('[doc2]','').replaceAll('[doc3]','')
    .replaceAll('[doc4]','').replaceAll('[doc5]','').replaceAll('[doc6]','').replaceAll('[doc7]','').replaceAll('[doc8]','').replaceAll('[doc9]','').replaceAll('[doc10]','');
    const conversationitem  = {
      conversationType: "incoming",  
      content:  responsechattext,
      contenttype: "plaintext"
    }

    //console.log("conversationitem",conversationitem)
    setconversationData(current => [...current, conversationitem]);   
  }else{
    const conversationitem  = {
      conversationType: "incoming",  
      content: "Something went wrong. Please try again later",
      contenttype: "plaintext"
    } 
    setconversationData(current => [...current, conversationitem]); 
  }      
  setshowcontentloading(false);
  ScrollToEndOfList();
}   

const handleChat =  () => {  
  if(!UserInput.current.value){
    return ;
  } 
  setshowcontentloading(true); 
  const conversationitem  = {
      conversationType: "outgoing",  
      content: UserInput.current.value,
      contenttype: "plaintext"
  }   
  setconversationData(current => [...current, conversationitem]); 
  ScrollToEndOfList(); 
  ScrollToEndOfList();
  generatetxtResponse(UserInput.current.value);  
} 
  
const handleKeyDown = (event) => { 
  if (event.altKey && event.key === 'Enter') {  
    let addnewline = UserInput.current.value + "\r\n"
    UserInput.current.value = addnewline; 
  }else if( event.key === 'Enter'){ 
    handleChat();
  }
}; 
  return (  
    <div className={'chatboxParent '+ languagePrefer } >  
    <div className="chatbot" >
      <ul id="chatboxcontainer" className="chatbox">  
          <li className="chat incoming">  
              {languagePrefer === "en-US" ? 
              <> <span> <img src={roboticonsm} className="botwelcomeimgEnsm" height="100" alt="Welcome" loading="lazy" /> </span><p> Good day. I'm Bahrain Credits's Virtual Assistant. Ask me anything about Credit Card and Sahel and i can answer</p>  </>
              : 
              <> <span> <img src={roboticonsm} className="botwelcomeimgEnsm" height="100" alt="Welcome" loading="lazy" /> </span><p>يوم جيد. أنا المساعد الافتراضي لشركة ائتمانات البحرين. اسألني أي شيء عن بطاقة الائتمان وساحل ويمكنني الإجابة
</p>  </>
              } 
          </li>
          {  
          conversationData.map((row) => (     
              <ConversationWidget 
                  conversationType =   { row.conversationType }
                  content =   { row.content } 
                  contenttype =   { row.contenttype }  
              /> 
          ))}
            {showcontentloading && ( 
              <img src={botimage2} className="aiprocessinggf" height="100" alt="AI Processing" loading="lazy" sh /> 
            )} 
      </ul> 
      <div> 
      <div className="chat-input">
        <div className='languageswitchcontainer'>
          <span className='languswitch'>AR</span>
          <div className="toggle-slide" onClick={handleClick}>
              <div className={`switch ${languagePreferAr  ? "slide " : ""}`}> </div>
          </div>
          <span className='languswitch'>EN</span></div>
          <div onKeyDown={handleKeyDown} tabIndex="0" className='chat-input-message'>
            <textarea  ref={UserInput} required></textarea>  
        </div>
        <span onClick={handleChat} ><FontAwesomeIcon icon={faPaperPlane} /></span>  
         {isRecording && ( 
        <span onClick={toggleRecording} ><FontAwesomeIcon className='pasuseicon' icon={faCirclePause} /></span> 
        )}
        {(!isRecording)&& ( 
          <span onClick={toggleRecording} ><FontAwesomeIcon icon={faMicrophone} /></span> 
        )}    
      </div>
  </div>
      </div> 
      </div> 
  );
} 
