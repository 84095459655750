export function sendChatRequest(userContent) {
    // const apiBase = "https://scw-openai4.openai.azure.com/";
    // const apiKey = "eeea3fdea61e4c4cb33e5faa304bb975";
    // const deploymentId = "scw-chat";
    // const searchEndpoint = "https://ubs-ai.search.windows.net";
    // const searchKey = "Qj8s1UcMYyTTp7qkL4kV21NnBa4Sfe1xeK6WXc6rTVAzSeD5oLnK";
    // // const searchIndex = "bc1";
    // const searchIndex = "scw-chat"; 

    // const apiBase = "https://urbansofttech2015-ai-aiservices.openai.azure.com/";
    // const apiKey = "b0e564334d0c427090955e0d46b049fd"; 
    // const deploymentId = "gpt-4-32k";
    // const searchEndpoint = "https://ubs-ai.search.windows.net";
    // const searchKey = "Qj8s1UcMYyTTp7qkL4kV21NnBa4Sfe1xeK6WXc6rTVAzSeD5oLnK"; 
    // const searchIndex = "bahrain-credit";  
    // const url = `${apiBase}/openai/deployments/${deploymentId}/extensions/chat/completions?api-version=2023-08-01-preview`;
    // const headers = {
    //     "Content-Type": "application/json",
    //     "api-key": apiKey,
    // };

    const apiBase = "https://scw-openai4.openai.azure.com/";
    const apiKey = "eeea3fdea61e4c4cb33e5faa304bb975"; 
    const deploymentId = "scw-chat";
    const searchEndpoint = "https://ubs-ai.search.windows.net";
    const searchKey = "Qj8s1UcMYyTTp7qkL4kV21NnBa4Sfe1xeK6WXc6rTVAzSeD5oLnK"; 
    const searchIndex = "bahrain-credit";  
    const url = `${apiBase}/openai/deployments/${deploymentId}/extensions/chat/completions?api-version=2023-08-01-preview`;
    const headers = {
        "Content-Type": "application/json",
        "api-key": apiKey,
    };


    const data = {
        dataSources: [
            {   
                type: "AzureCognitiveSearch",
                parameters: {
                    endpoint: searchEndpoint,
                    key: searchKey,
                    indexName: searchIndex,   
                },
            },
        ],
        messages: [
            {
                role: "system",
                content: "You are an AI Assistant whose primary goal is to help users with Bahrain Credit inquiries. \nProvide concise replies that are polite and professional.\n Respond to user 'I can not understand your question. You may be able to ask me in different way' if you are not able to retrieve the details, \nCPR stands for the ID card issued by the Bahrain government, and it stands for Central Population Registry.\nIf the question is in Arabic, translate it to English before generating the search query, answer back result in Arabic.\nDon't Provide retrieved document reference number and retrieved document details. "
            }, 
            {
                role: "user",
                content: userContent,
            }
        ],
        max_tokens:1224,
        temperature: 0,
        frequency_penalty: 0,
        presence_penalty: 0,
        top_p: 1,
        stop: null,  

    };

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => { 
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => { 
                console.log(data); 
                resolve(data);
            })
            .catch((error) => { 
                reject(error);
            });
    });
}